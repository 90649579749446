/* /components/ui/tabs.css */

.tab-container {
  display: flex;
  flex-direction: column;
}

.tab-list {
  display: flex;
  border-bottom: 2px solid #ddd;
}

.tab {
  padding: 10px 20px;
  cursor: pointer;
  border: 1px solid transparent;
  border-radius: 5px 5px 0 0;
  background-color: #f0f0f0;
  transition: background-color 0.3s, border-color 0.3s;
}

.tab.active {
  background-color: white;
  border-color: #ddd;
  border-bottom: 1px solid white;
}

.tab-panel {
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 0 0 5px 5px;
  background-color: white;
}
